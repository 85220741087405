/* Basic setup */

* {
  --color-black: rgb(20, 20, 20);
  --color-white: #ffffff;
  --color-tickle-pink: #ffccdd;
  --color-orchid-pink: #ff7faa;
  --color-aqua: #7fffd4;
  --color-aero-blue: #ccffee;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

body {
  font-family: "radikalregular";
  color: var(--color-white);
  position: relative;
  overflow-x: hidden;
  background-color: var(--color-black);
  text-rendering: optimizeLegibility;
}

.introAnim {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-black);
  z-index: 1000;
}

/* NavBar start */

.headerNavBar {
  width: 6vw;
  height: 99vh;
  background-color: transparent;
  position: fixed;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;
  flex-direction: column;
  z-index: 16;
  padding: 0 0.5vw;
}

.headerNavBar::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: -10%;
  transform: translate(0, -50%);
  width: 0.04vw;
  height: 100%;
  background: var(--color-white);
  opacity: 0.2;
}

.navLabel {
  position: relative;
}

.navLabel span {
  font-family: "radikalregular";
  padding: 1vw 2vw;
  background: var(--color-white);
  color: var(--color-black);
  position: absolute;
  top: -50%;
  left: 380%;
  transform: translate(0, -50%);
  opacity: 0;
  transition: all 0.4s ease;
  font-size: 1.2vw;
  text-transform: uppercase;
  pointer-events: none;
}

.roomLabel span {
  left: 270%;
}

.socialsDiv {
  position: absolute;
  bottom: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1vh;
  font-weight: light;
  font-size: 0.8vw;
}

.socialsDiv a {
  color: var(--color-white);
  text-decoration: none;
}

.navsvgFill,
.navsvgStroke {
  transition: all 1s ease;
}

.openmod input {
  display: none;
}

.navBookBtn {
  position: absolute;
  top: -1%;
  left: 0;
  width: 6.55vw;
  height: 7vw;
  background: var(--color-white);
  color: var(--color-black);
  font-family: "radikalregular";
  transition: all 0.4s ease;
}

.navBookBtn .navBookText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.25vw;
  cursor: pointer;
  transition: all 0.4s ease;
}

.navBookBtn:hover {
  background: var(--color-aero-blue);
}

.navBookBtn:hover .navBookText {
  color: var(--color-black);
}

.navMenuBg {
  width: 3.8vw;
  height: 3.8vw;
  background-color: var(--color-black);
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
}

.openMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 3.5vw;
  height: 3.5vw;
  background-color: var(--color-white);
  overflow: hidden;
  cursor: pointer;
}

.openMenu span {
  border-radius: 0.6510416666666666vw;
  height: 0.45vh;
  margin: 0.46vh 0;
  transition: all 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  z-index: 10;
  cursor: pointer;
}

.openMenu span:nth-of-type(1) {
  width: 55%;
  background: var(--color-black);
}

.openMenu span:nth-of-type(2) {
  width: 50%;
  background: var(--color-black);
}

.openMenu span:nth-of-type(3) {
  width: 55%;
  background: var(--color-black);
}

.openMenu input[type="checkbox"] {
  display: none;
}

/* 
.openMenu input[type="checkbox"]:checked ~ span:nth-of-type(1) {
  transform-origin: top;
  transform: rotatez(45deg) translate(0.55vw, 0.25vw);
}

.openMenu input[type="checkbox"]:checked ~ span:nth-of-type(2) {
  opacity: 0;
}

.openMenu input[type="checkbox"]:checked ~ span:nth-of-type(3) {
  transform-origin: bottom;
  transform: translate(0vw, -1.2vw) rotatez(135deg);
} */

.openMenu:hover span:nth-of-type(2) {
  /* width: 50%; */
}

.openMenu:hover span:nth-of-type(1),
.openMenu:hover span:nth-of-type(3) {
  /* width: 25%; */
}

/* Menu icon end */

.headerNavHover {
  position: fixed;
  top: 49.5%;
  left: 8vw;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2vw;
  z-index: 16;
  /* padding: 0 0.5vw; */
}

.hoverEl {
  height: 3.5vw;
  color: var(--color-black);
  background-color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1vw 2vw;
  opacity: 0;
}

/* Navbar openModal  start */

.modalContainer {
  position: fixed;
  top: 0;
  left: -200%;
  width: 30vw;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 11;
  background-color: var(--color-black);
  color: var(--color-white);
  display: grid;
  flex-direction: column;
  justify-content: center;
}

.modal_heading {
  margin: 3vh 0vh 2vh 0vh;
  width: 26vw;
  height: 20vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_heading::before {
  position: absolute;
  top: 0;
  content: "";
  display: block;
  width: 100%;
  height: 0.1vh;
  background-color: var(--color-white);
}

.modal_heading::after {
  position: absolute;
  bottom: 0;
  content: "";
  display: block;
  width: 100%;
  height: 0.1vh;
  background-color: var(--color-white);
}

.modal_title {
  text-align: center;
  width: 80%;
  font-size: 2vw;
  position: relative;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1.5;
}

.modal_box {
  width: 26vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  padding-top: 2vh;
  padding-bottom: 2vh;
  margin-top: 5vh;
  z-index: 20;
}

.modal_box::after {
  position: absolute;
  bottom: -5%;
  content: "";
  display: block;
  width: 100%;
  height: 0.1vh;
  background-color: var(--color-white);
}

.modal_box h6 {
  font-size: 1.25vw;
  font-weight: 400;
}

.modal_box a {
  align-self: flex-end;
  color: var(--color-white);
  font-size: 1vw;
  /* text-decoration: none; */
  margin-top: 5vh;
  transition: all 0.2s ease;
  z-index: 20;
}

.modal_box a:hover {
  color: var(--color-orchid-pink);
}

.modal_box img {
  width: 26vw;
  height: 40vh;
  object-fit: cover;
  margin-top: 2.5vh;
}

.modal_box p {
  margin-top: 2.5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1vw;
  align-self: center;
}

.locationBox::after {
  position: absolute;
  bottom: 0;
  content: "";
  display: block;
  width: 0%;
  height: 0.1vh;
  background-color: var(--color-aero-blue);
}

.locationModalMap {
  width: 26vw;
  height: 60vh;
  object-fit: cover;
  object-position: right;
}

/* Navbar openModal  end */

/* NavBar end */

/* MenuContainer start */

.nav-container {
  position: fixed;
  top: 0;
  left: -500%;
  width: 100vw;
  height: 100vh;
  background: var(--color-black);
  z-index: 14;
}

.nav-container .menu {
  width: 100vw;
  height: 100vh;
  --offset: 0vw;
  --move-initial: calc(-25% + var(--offset));
  --move-final: calc(-50% + var(--offset));
  display: flex;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  gap: 20vw;
  color: var(--color-white);
}

/*------------------  */

.navMenuIcons {
  position: absolute;
  right: 0;
  bottom: 5vh;
  padding-right: 5vw;
  z-index: 1;
}

.icon-list {
  display: flex;
  justify-content: space-between;
}

.icon-item {
  list-style: none;
}

.icon-link {
  display: inline-flex;
  font-size: 1.171303074670571vw;
  text-decoration: none;
  color: var(--color-white);
  width: 3vw;
  height: 3vw;
  transition: 0.5s linear;
  position: relative;
  z-index: 1;
  margin: auto;
}

.icon-link:hover {
  color: #fff;
}

.icon-link i {
  margin: auto;
}

.icon-link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 3vw;
  height: 3vw;
  background: #181818;
  border-radius: 50%;
  z-index: -1;
  transform: scale(0);
  transition: 0.3s cubic-bezier(0.95, 0.32, 0.37, 1.21);
}

.icon-link:hover::before {
  transform: scale(1);
}

.icon-item:nth-of-type(1) a:hover:before {
  background: #3b5999;
}

.icon-item:nth-of-type(2) a:hover:before {
  background: #e4405f;
}

.icon-item:nth-of-type(3) a:hover:before {
  background: #ee0f47;
}

.menu-grid-2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-grid-2 ul {
  list-style: none;
  font-size: 2.5vw;
}

.menu-grid-2 ul .navConLink {
  padding-top: 10vh;
}

.menu-grid-2 .link {
  color: var(--color-white);
  height: 2.5vw;
  align-items: center;
  text-decoration: none;
  display: flex;
  text-transform: lowercase;
}

.menu-grid-2 .mask {
  position: relative;
  padding: 0;
  height: 2.5vw;
  overflow: hidden;
}

.menu-grid-2 .link-container {
  transition: transform 0.4s ease;
}

.menu-grid-2 .title {
  display: block;
  font-size: 2.5vw;
  line-height: 2vw;
  transition: transform 0.4s ease;
}

.menu-grid-2 .title span {
  font-size: 1.5vw;
  padding-right: 2.5vw;
  color: var(--color-white);
}

.menu-grid-2 .link-title1 {
  transform-origin: right center;
}

.menu-grid-2 .link-title2 {
  transform-origin: left center;
  transform: rotate(20deg);
}

.menu-grid-2 .link-icon {
  position: relative;
  width: 3.3255208333333333vw;
  height: 3.3255208333333333vw;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.6510416666666666vw;
  overflow: hidden;
}

.menu-grid-2 .icon {
  display: block;
  position: absolute;
  transition: transform 0.4s ease;
  visibility: hidden;
}

.menu-grid-2 .icon:nth-child(2) {
  transform: translate(-2.6041666666666665vw);
}

/* Hover Action*/

.menu-grid-2 .link:hover .link-container {
  color: var(--color-white);
  transform: translateY(-2.5vw);
}

.menu-grid-2 .link:hover .link-title1 {
  transform: rotate(20deg);
}

.menu-grid-2 .link:hover .link-title2 {
  transform: rotate(0);
}

.menu-grid-2 .link:hover .icon:first-child {
  visibility: visible;
  transform: translate(2.6041666666666665vw);
}

.menu-grid-2 .link:hover .icon:nth-child(2) {
  visibility: visible;
  transform: translate(0vw);
}

.menu-grid-3 {
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15vh;
  font-size: 2vw;
  position: relative;
  font-family: "radikalregular";
  padding-left: 5vw;
}

.menu-grid-3 .menu-grid-3-sub1 {
  display: flex;
  flex-direction: column;
}

.menu-grid-3 .menu-grid-3-sub1 span {
  margin: 2vh 0;
}

.menu-grid-3 .menu-grid-3-sub2 {
  display: grid;
  justify-content: 1fr;
  grid-row-gap: 5vh;
}

.sub_rooms {
  position: absolute;
  right: -90%;
  font-size: 1.25vw;
  display: flex;
  flex-direction: column;
  gap: 5vh;
  visibility: hidden;
}

.sub_rooms a {
  text-decoration: none;
  color: var(--color-white);
}

.sub_room_link {
  opacity: 0;
}

.sub_rooms a::after {
  content: "";
  display: block;
  background-color: var(--color-white);
  width: 0%;
  height: 0.25vh;
  transition: all 0.3s ease;
}

.sub_rooms a:hover::after {
  width: 45%;
}

#roomNavLink {
  position: relative;
  width: 100%;
}

/* -------------------------- */

/* Mobile menu start */
.mobileNav {
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 21;
  width: 100vw;
  height: 100vh;
  background-color: #181818;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--color-white);
  padding-left: 10vw;
  overflow: hidden;
}

.open_mobile_nav{
  position: fixed;
  right: 7.5vw;
  top: 5vh;
  color: var(--color-white);
  font-size:4vw;
  z-index: 20;
  opacity: 0;
  display: none;
}

.close_mobileNav{
  position: absolute;
  right: 7.5vw;
  top: 5vh;
  font-size: 4vw;
  opacity: 0;
}

.mobileNav ul {
  margin-top: -17.5vh;
  list-style: none;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 5vh;
}

.mobileNav ul li a {
  text-decoration: none;
  color: var(--color-white);
  font-size: 6vw;
}


.mobileNavSocial{
  position: absolute;
  bottom: 15%;
  left: 11vw;
  display: flex;
 justify-content: center;
 align-items: center;
  gap: 5vw;
}

.mobileNavSocial a{
text-decoration: none;
color: var(--color-white);
}


/* Mobile menu end */


/* ------------------------------------ */
/* header start */

.header {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.headerBgLayer {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100vw;
  height: 100vh;
  padding-left: 4vw;
  background-color: var(--color-black);
  z-index: 10;
  opacity: 0.98;
}

.headerLogo {
  position: absolute;
  top: 6.5vw;
  right: -10vw;
  transform: translate(-55%, -50%);
  z-index: 2;
  transition: all 0.4s ease;
}

.headerVideoLayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #181818;
  opacity: 0.85;
}

.headervideoContainer {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.headervideoContainer video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.headerHeroheading h6 {
  font-size: 1vw;
  position: relative;
  padding-left: 0.5vw;
  display: none;
}

.headerHeroheading h6::after {
  content: "";
  display: block;
  width: 5vw;
  height: 0.25vh;
  background-color: var(--color-white);
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translate(0, -50%);
  transition: width 0.4s ease;
  opacity: 0.2;
}

.headerHeroheading {
  position: absolute;
  top: 55%;
  left: 13vw;
  transform: translate(0, -50%);
  z-index: 1;
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  gap: 4vh;
  text-align: left;
}

.headerHeroheading h2 {
  font-family: "sudregular";
  mix-blend-mode: exclusion;
}

.large_Heading_h2 {
  font-size: 4.5vw;
}

.small_Heading_h2 {
  display: none;
}

.headerHeroheading p {
  font-size: 1vw;
}

.headerQuickNavbar {
  position: absolute;
  bottom: 4vw;
  left: 13vw;
  display: flex;
  flex-direction: row;
  gap: 12.5vw;
}

.headerQuickNavbar div {
  position: relative;
  font-size: 1.1vw;
  cursor: pointer;
}

.headerQuickNavbar div span {
  font-size: 1.25vw;
  padding-right: 0.35vw;
}

.headerQuickNavbar div::before {
  content: "";
  display: block;
  width: 2vw;
  height: 0.2vh;
  background-color: var(--color-aqua);
  position: absolute;
  top: -100%;
  transition: width 0.4s ease;
}

.headerQuickNavbar div::after {
  content: "";
  display: block;
  width: 15vw;
  height: 0.2vh;
  background-color: var(--color-white);
  position: absolute;
  top: -100%;
  opacity: 0.2;
}

.headerQuickNavbar div:hover::before {
  width: 15vw;
}

/* Header end */

/* AboutUs Section Start */
.aboutUs_Section {
  position: relative;
  width: 100vw;
  height: auto;
  padding-left: 11vw;
  padding-top: 25vh;
}

.aboutUs-heading {
  width: 65%;
  font-family: "sudregular";
  font-size: 3vw;
  letter-spacing: 0.05vw;
  font-weight: normal;

}

.aboutUs-detail {
  position: relative;
  width: 45%;
  margin-top: 10vh;
  margin-left: 30vw;
  font-size: 1.25vw;
  letter-spacing: 0.05vw;
}

.aboutUs-detail span {
  position: absolute;
  left: 0;
  margin-top: 10vh;
}

.aboutUs-detail span::after {
  content: "";
  display: block;
  width: 100%;
  height: 0.04vh;
  background-color: var(--color-white);
  transition: all 0.3s ease;
  position: absolute;
  left: 0;
  bottom: 0;
}

.aboutUs-detail span:hover::after {
  width: 20%;
}

.aboutUs-imgContainer {
  display: flex;
  flex-direction: column;
  gap: 5vh;
  height: 100%;
  margin-top: 20vh;
}

.about_subImgContainer {
  display: flex;
  flex-direction: row;
  gap: 2vw;
  align-items: flex-end;
}

.aboutImgAnim1-container {
  width: 40vw;
  height: 80vh;
}

.about_subImgContainer .aboutImgAnim1 {
  width: 40vw;
  height: 80vh;
  object-fit: cover;
}

.about_subImgContainer .aboutImgAnim2 {
  width: 45vw;
  height: 60vh;
  object-fit: cover;
}

.aboutImgAnim3 {
  width: 87vw;
  object-fit: cover;
}


.abt_small_img{
  display: none;
}

.aboutServices {
  display: flex;
  flex-direction: column;
  margin-top: 20vh;
  gap: 10vh;
}

.servicesTitle {}

.servicesTitle h5 {
  font-size: 3vw;
  font-family: "sudregular";
  font-weight: normal;
}

.servicesTitle p {
  width: 40%;
  /* margin-top: 15vh; */
}

.servicesBox {
  padding-top: 2.5vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.serviceBox {
  display: flex;
  flex-direction: column;
  gap: 3vh;
}

.serviceNum {
  /* text-align: center; */
}

.serviceNum img {
  width: 12.5%;
}

.serviceTitle {
  margin-top: -1vh;
  font-size: 1.5vw;
  font-family: "radikalregular";
  font-weight: normal;

}

.serviceBox p {
  width: 75%;
  text-align: left;
  font-size: 0.95vw;
}


/* AboutUs Section End */

/* ------------------------------------------------------------------ */

/* Rooms section start */

.roomsHeading {
  padding: 25vh 0vh 15vh 12vw;
  display: flex;
  flex-direction: column;
  gap: 8vh;
}

.roomsTitle {
  font-size: 3vw;
  font-family: "sudregular";
  letter-spacing: 0.2vw;
  font-weight: normal;

}

.roomsText {
  margin-left: 20vw;
  width: 50%;
  font-size: 1.25vw;
  letter-spacing: 0.05vw;
}

/* .roomsHeading a {
  margin-left: 20vw;
  color: var(--color-white);
  position: relative;
  text-decoration: none;
}

.roomsHeading a::after {
  content: "";
  display: block;
  width: 14%;
  height: 0.04vh;
  background-color: var(--color-white);
  transition: all 0.3s ease;
  position: absolute;
  left: 0;
  bottom: 0;
}

.roomsHeading a:hover::after {
  width: 2.5%;
} */

.roomsSection {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.image-slider {
  z-index: 0;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  height: 100vh;
}

.image-slide {
  height: 100vh;
  margin: 0 auto;
}

.slick-slide.slick-center {
  transform: scale(1.2);
  transition: transform 0.8s 1.4s cubic-bezier(0.84, 0, 0.08, 0.99);
}

.slick-slide {
  transition: transform 0.7s cubic-bezier(0.84, 0, 0.08, 0.99);
}

.slider-control {
  margin: 0%;
  position: absolute;
  z-index: 2;
  bottom: 8%;
  left: 15%;
  transform: translate(-50%, -50%);
  display: flex;
}

.slider-control button {
  color: #fff;
  background: none;
  padding: 1.25vw;
  border: 0.052083333333333336vw solid rgba(255, 255, 255, 0.3);
  font-size: 0.8333333333333334vw;
  border-radius: 50%;
  margin: 0.4em;
  display: inline-block;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.slider-control button:focus {
  outline: none;
}

.slider-control button i {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.slider-control button:hover {
  transform: scale(1.25);
}

.slider-control button:hover i {
  transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
}

.block-1 {
  z-index: 1;
  position: absolute;
  height: 100vh;
  width: 5%;
  left: 0%;
  background: rgb(20, 20, 20);

}

.block-2 {
  z-index: 1;
  position: absolute;
  height: 100vh;
  width: 25%;
  left: 25%;
  background: rgb(20, 20, 20);
}

.block-3 {
  z-index: 1;
  position: absolute;
  height: 100vh;
  width: 5%;
  right: 0%;
  background: rgb(20, 20, 20);
}

.overlay {
  z-index: 1;
  position: absolute;
  height: 100vh;
  width: 20.2%;
  left: 5%;
  background: rgba(0, 0, 0, 0.85);
}

.text-slider-wrapper {
  z-index: 2;
  position: absolute;
  width: 100%;
  top: 32.5%;
}

.text-slider {
  margin: 0%;
  padding: 0%;
  height: 100vh;
}

.text-slide {
  position: relative;
}

.text-slide h1 {
  color: #fff;
  font-size: 3.3333333333333335vw;
  font-weight: lighter;
  text-transform: uppercase;
  padding-left: 10%;
  font-family: "sudregular";
}

.text-slide h6 {
  font-size: 1.1vw;
  margin-top: 2.5%;
  /* margin-right: 60%; */
  /* text-align: right; */
  text-align: left;
  margin-left: 27.5%;
  display: flex;
  flex-direction: column;
  gap: 3.5vh;
  position: relative;
  font-family: "radikalregular";
  font-weight: normal;
}

.text-slide h6 span {
  position: relative;
}

.text-slide h6 span::after {
  content: "";
  display: block;
  width: 2%;
  height: 0.1vh;
  background-color: var(--color-white);
  position: absolute;
  bottom: -15%;
  left: 0;
}

.text-slide a {
  margin-top: 1vh;
  color: var(--color-white);
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
}

.text-slide a::after {
  content: "";
  display: block;
  width: 0%;
  height: 0.1vh;
  background-color: var(--color-orchid-pink);
  position: absolute;
  bottom: -15%;
  left: 0;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.text-slide a:hover::after {
  width: 7.5%;
}

.text-slide a:hover {
  color: var(--color-orchid-pink);
}

/* Rooms section end */

/* ------------------------------------------------------------------ */

/* ------------------------------------------------------------------ */
/* Dining section start */
.diningSection {
  width: 100vw;
  height: 100%;
  padding: 20vh 0;
  display: flex;
  flex-direction: column;
  gap: 10vh;
}

.diningTitle {
  font-size: 3vw;
  font-family: "sudregular";
  letter-spacing: 0.2vw;
  padding-left: 11vw;
}

.diningModal {
  margin-top: 7.5vh;
  display: flex;
  flex-direction: column;
  padding-left: 11vw;
  padding-right: 2vw;
  gap: 5vh;
  position: relative;
}

.diningModalTitle {
  font-size: 3vw;
}

.diningModalHeading {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.diningModalHeading span {
  width: 65%;
  text-align: justify;
}

.diningNav a {
  text-decoration: none;
  color: var(--color-white);
  padding-right: 2vw;
  position: relative;
}

.diningNav a::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  display: block;
  width: 60%;
  height: 0.04vh;
  background-color: var(--color-white);
  transition: all 0.3s ease;
}

.diningNav a:hover::after {
  width: 0;
}

.diningModal img {
  margin-top: 5vh;
  width: 100%;
  height: 70vh;
  object-fit: cover;
  padding-right: 1.5vw;
}

.cafeBgLayer,
.wineBgLayer {
  position: absolute;
  bottom: -10%;
  left: 0;
  width: 100%;
  height: 80%;
  background-color: var(--color-black);
  z-index: 2;
}

/* Dining section end */

/* ------------------------------------------------------------------ */

/* ------------------------------------------------------------------ */
/* Gallery section start */
.gallerySection {
  width: 100vw;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10vh;
}

.galleryHeroDiv {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2vw;
}

.galleryHeroImg {
  position: relative;
}

.galleryHeroImg img {
  width: 65vw;
  height: 95vh;
  object-fit: cover;
}

.galleryTitle {
  position: absolute;
  bottom: 1vh;
  left: 13vw;
  font-size: 6vw;
  font-family: "sudregular";
}

.galleryImgDiv {
  display: flex;
  flex-direction: column;
  gap: 2vh;
}

.galleryImgDiv img {
  width: 30vw;
  object-fit: cover;
}

.galleryImgDetail {
  font-size: 1vw;
}

.galleryContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10vh;
}

/* Gallery section end */

/* ------------------------------------------------------------------ */

/* ------------------------------------------------------------------ */
/* contact section start */
.contactSection {
  width: 100vw;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  padding-left: 13vw;
  padding-top: 25vh;
}

.contactTitle {
  font-size: 3vw;
  font-family: "sudregular";
  letter-spacing: 0.2vw;
  font-weight: normal;
}

.contactSection .locationMap {
  margin-top: 10vh;
  width: 80vw;
  height: 70vh;
  object-fit: cover;
  /* filter: grayscale(100%); */
}

.contactDetails {
  padding: 20vh 10vw 20vh 5vw;
  display: flex;
  flex-direction: column;
  gap: 7.5vh;
}

.contactDetails div {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.contactDetails div .contactDetailsTitle {
  font-size: 1.35vw;
}

.contactDetails div h6 {
  font-size: 1vw;
  font-weight: normal;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: left;
}

.contactDetails div:nth-of-type(1) p {
  width: 100%;
  font-size: 1vw;
}

.contactDetails div:nth-of-type(2) div {
  margin-top: 7.5vh;
  display: flex;
  flex-direction: column;
  gap: 0.5vh;
}

.contactInfo::before,
.contactAddress::before {
  content: "";
  display: block;
  width: 85%;
  height: 0.025vh;
  background-color: #fff;
  margin-bottom: 1vh;
}

.contactDetails div:nth-of-type(3) div {
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  gap: 0.5vh;
}

/* contact section end */

/* ------------------------------------------------------------------ */

/* ------------------------------------------------------------------ */
/* Footer section start */
footer {
  width: 100vw;
  height: 100%;
  background-color: #181818;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 5vh;
  padding-bottom: 5vh;
  position: relative;
}

footer h6 {
  margin-left: 13vw;
  font-size: 1.25vw;
  font-weight: normal;
  text-transform: uppercase;
}

.footerNavContainer {
  display: flex;
  flex-direction: column;
  gap: 10vh;
}

.footerNav {
  margin-left: 7.5vw;
  display: grid;
  grid-template-columns: 7.5vw 7.5vw;
  grid-gap: 1vh;
}

.footerNav a {
  text-decoration: none;
  color: #fff;
  font-size: 1vw;
  transition: all 0.2s ease;
}

.footerNav a:hover {
  color: var(--color-orchid-pink);
}

.footerSocial {
  display: flex;
  flex-direction: row;
  gap: 4vw;
  font-size: 1vw;
  position: absolute;
  bottom: 15%;
  left: 13vw;
}

.footerSocial a {
  color: var(--color-white);
}

.footerEnd {
  margin-left: 7.5vw;
  font-size: 0.95vw;
}

/* Footer section end */
/* ------------------------------------------------------------------ */